(() => {
  const CONSTANTS = {
    resourceDownloadModalId: 'resource-download-modal',
    resourceDownloadCTAClass: 'resources-download-trigger',
  };

  const handleModalConfirm = ({ id }) => {
    if (id === CONSTANTS.resourceDownloadModalId) {
      // Get the resource download path from window and download the resource
      const ghostLink = document.createElement('a');
      ghostLink.target = '_blank';
      ghostLink.href = window._downloadableResourcePath;
      ghostLink.click();

      // Close the modal after resource download
      const modal = document.querySelector(
        `div[data-id="${CONSTANTS.resourceDownloadModalId}"]`
      );
      const close = modal?.querySelector(
        '.close.main-close'
      ) as HTMLButtonElement;
      close?.click();
    }
  };

  const appendEvents = () => {
    const resourceDownloadCTAs = document.querySelectorAll(
      `.${CONSTANTS.resourceDownloadCTAClass}`
    ) as NodeListOf<HTMLAnchorElement>;

    if (resourceDownloadCTAs.length) {
      resourceDownloadCTAs.forEach(downloadCTA => {
        const resourceLink = downloadCTA.getAttribute('data-href');
        if (resourceLink) {
          // Add the resource download path to window
          downloadCTA.addEventListener('click', e => {
            e.preventDefault();
            window._downloadableResourcePath = resourceLink;
          });
        }
      });
    }
  };

  const init = () => {
    appendEvents();

    if (window.Bus) {
      window.Bus.on('aaous-modal:confirm-click', handleModalConfirm);
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
