(() => {
  const CONSTANTS = {
    teaserContainerClass: 'teaser-container',
    disclaimerClass: 'emu-teaser__disclaimer',
    disclaimerBottomRightClass: 'disclaimer-bottom-right',
    disclaimerBottomLeftClass: 'disclaimer-bottom-left',
    disclaimerBottomCenterClass: 'disclaimer-bottom-center',
    disclaimerLightThemeClass: 'text-theme-light',
    disclaimerDarkThemeClass: 'text-theme-dark',
    disclaimerShowClass: 'show',
  };

  // for teaser with just image and no content, padding is being added for the empty div
  // to avoid that, detecting the content and adding a class, so that the space can be removed
  const isTeaserTextEmpty = teaser => {
    if (teaser) {
      const teaserContent = teaser.querySelector('.content')?.innerText || '';
      if (teaserContent?.trim()?.length === 0) {
        teaser
          .querySelector('.cmp-teaser')
          .classList.add('u-teaser--no-content');
      }
    }
  };

  // updates the disclaimer to be a child of `teaser-container` for better positioning
  const updateDisclaimerParent = teaser => {
    const disclaimer: HTMLDivElement = teaser.querySelector(
      `.${CONSTANTS.disclaimerClass}`
    );
    const teaserContainer: HTMLDivElement = teaser.querySelector(
      `.${CONSTANTS.teaserContainerClass}`
    );

    if (disclaimer && teaserContainer) {
      const parent = disclaimer.parentElement;
      const isDisclaimerOnLeft = parent?.classList.contains(
        CONSTANTS.disclaimerBottomLeftClass
      );
      const isDisclaimerOnCenter = parent?.classList.contains(
        CONSTANTS.disclaimerBottomCenterClass
      );

      const isTextThemeLight = parent?.classList.contains(
        CONSTANTS.disclaimerLightThemeClass
      );

      // update the classes for disclaimer position (left/right)
      if (isDisclaimerOnCenter) {
        teaserContainer.classList.add(CONSTANTS.disclaimerBottomCenterClass);
      } else if (isDisclaimerOnLeft) {
        teaserContainer.classList.add(CONSTANTS.disclaimerBottomLeftClass);
      } else {
        teaserContainer.classList.add(CONSTANTS.disclaimerBottomRightClass);
      }

      // update the classes for text theme for disclaimer
      isTextThemeLight
        ? disclaimer.classList.add(CONSTANTS.disclaimerLightThemeClass)
        : disclaimer.classList.add(CONSTANTS.disclaimerDarkThemeClass);

      // update the disclaimer parent
      teaserContainer.appendChild(disclaimer);

      // show the disclaimer
      disclaimer.classList.add(CONSTANTS.disclaimerShowClass);
    }
  };

  // updates the image height if the container height is greater
  const checkImageHeight = ($image, $container) => {
    const containerHeight = $container.offsetHeight,
      imageHeight = $image.offsetHeight;

    if (containerHeight > imageHeight) {
      $image.style.minHeight = `${containerHeight}px`;
    }
  };

  // for the overlay teaser, if the content is large avoid overflow by updating the mobile image height
  const handleOverlayTeaserContent = teaser => {
    const $overlayTeaser = teaser.querySelector(
      '.u-teaser--overlay-text-in-mobile'
    );

    if ($overlayTeaser) {
      const $contentContainer =
        $overlayTeaser.querySelector('.content-container');

      const $mobileImage = $overlayTeaser.querySelector(
        '.cmp-teaser__image--mobile img'
      );

      checkImageHeight($mobileImage, $contentContainer);

      window.addEventListener('resize', () => {
        checkImageHeight($mobileImage, $contentContainer);
      });
    }
  };

  const makeTeaserVisible = ($teaser: HTMLElement, timer?) => {
    if (timer) {
      clearTimeout(timer);
    }
    $teaser?.classList?.add('u-teaser--img-loaded');
  };

  const handleTeaserHeight = ($teaser: HTMLElement) => {
    const $teaserImgs = $teaser?.querySelectorAll(
      'img'
    ) as NodeListOf<HTMLImageElement>;
    if ($teaserImgs.length) {
      $teaserImgs.forEach($teaserImg => {
        let timer, imgCompleteTimer;
        $teaserImg.addEventListener('load', () => {
          makeTeaserVisible($teaser, timer);
        });

        // if the image already loaded, then make the teaser visible
        if ($teaserImg?.complete) {
          // to make sure that the opacity animation is complete, adding the timer here
          imgCompleteTimer = setTimeout(() => {
            clearTimeout(timer);
            makeTeaserVisible($teaser, imgCompleteTimer);
          }, 500);
        }

        // making sure that the teaser will appear after 5 seconds in any case
        timer = setTimeout(() => {
          makeTeaserVisible($teaser);
        }, 5000);
      });
    } else {
      makeTeaserVisible($teaser);
    }
  };

  const handleTeaser = teaser => {
    isTeaserTextEmpty(teaser);
    updateDisclaimerParent(teaser);
    handleOverlayTeaserContent(teaser);
    handleTeaserHeight(teaser);
  };

  const init = () => {
    const teasers = document.querySelectorAll('.teaser');

    if (teasers.length) {
      teasers.forEach(teaser => {
        handleTeaser(teaser);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
