(() => {
  const CONSTANTS = {
    countrySelectorClass: 'country-selector',
    countrySelectorShowClass: 'country-selector--show',
    dropdownClass: 'country-selector__dropdown',
    countryDropdownName: 'country-dropdown',
    languageDropdownName: 'language-dropdown',
    mainContentClass: 'country-selector__main-content',
    mainContentActiveClass: 'country-selector__main-content--show',
    footerContentClass: 'footer__content',
    pageCountryKey: 'page-country',
    pageLanguageKey: 'page-language',
    pfMainContentClass: 'country-pf__main-content',
    pfFooterContentClass: 'country-pf__footer-content',
    submitButtonClass: 'country-selector__button',
    deepLinkingLSKey: '_ral',
    logoutClickedLSKey: 'logoutClicked',
  };

  const initCountrySelector = $countrySelectorEl => {
    let pageFragId: string,
      $countryDropdown: HTMLSelectElement,
      $languageDropdown: HTMLSelectElement,
      $mainPFContent: HTMLElement,
      $footerPFContent: HTMLElement,
      $mainPageContent: HTMLElement,
      $footerPageContent: HTMLElement,
      $submitButton: HTMLAnchorElement,
      pfLoadTimer,
      pfShowTimer,
      deepLinkingURL,
      logoutClicked;

    const initVariables = (initialLoad = true) => {
      $countryDropdown = $countrySelectorEl.querySelector(
        `.${CONSTANTS.dropdownClass} select[name=${CONSTANTS.countryDropdownName}]`
      ) as HTMLSelectElement;
      $languageDropdown = $countrySelectorEl.querySelector(
        `.${CONSTANTS.dropdownClass} select[name=${CONSTANTS.languageDropdownName}]`
      ) as HTMLSelectElement;
      $mainPageContent = $countrySelectorEl.querySelector(
        `.${CONSTANTS.mainContentClass}`
      ) as HTMLElement;
      $footerPageContent = document.querySelector(
        `.${CONSTANTS.footerContentClass}`
      ) as HTMLElement;
      $submitButton = $mainPageContent.querySelector(
        `.${CONSTANTS.submitButtonClass}`
      ) as HTMLAnchorElement;
      deepLinkingURL = localStorage.getItem(CONSTANTS.deepLinkingLSKey) || '';
      logoutClicked = localStorage.getItem(CONSTANTS.logoutClickedLSKey) || '';

      // if country value exists in local storage, update the `$countryDropdown` with this value
      // we are not updating the `$languageDropdown` as it can be authored for each language variation
      const selectedCountry = localStorage.getItem(CONSTANTS.pageCountryKey);
      const selectedLanguage = localStorage.getItem(CONSTANTS.pageLanguageKey);

      if (selectedCountry && selectedCountry !== '--') {
        $countryDropdown &&
          Array.from($countryDropdown.options).forEach(option => {
            if (option.value === selectedCountry) {
              option.selected = true;
            } else {
              option.selected = false;
            }
          });

        // updating form options v2 state
        // @ts-ignore
        $countryDropdown?._update?.();

        // on initial page load update the `pageFragId` and fetch content
        if (initialLoad) {
          pageFragId = `${selectedCountry}${
            selectedLanguage && selectedLanguage !== '--'
              ? `-${selectedLanguage}`
              : ''
          }`;

          fetchPageFragment(initialLoad);
        }
      } else if (initialLoad) {
        // if country value does not exist in local storage, on initial page load show the content
        $mainPageContent?.classList.add(CONSTANTS.mainContentActiveClass);
      }
    };

    const appendEvents = () => {
      $countryDropdown?.addEventListener('change', () => {
        // if country selection is same as the value in local storage, return
        if (
          $countryDropdown?.value ===
          localStorage.getItem(CONSTANTS.pageCountryKey)
        ) {
          return;
        }

        // reset `$languageDropdown` for new country selection to prevent incorrect language value for the new country
        $languageDropdown &&
          Array.from($languageDropdown?.options).forEach(option => {
            option.selected = false;
          });

        // @ts-ignore
        $languageDropdown?._update?.();

        fetchPageFragment();
      });

      $languageDropdown?.addEventListener('change', () => {
        // if language selection is same as the value in local storage, return
        if (
          $languageDropdown?.value ===
          localStorage.getItem(CONSTANTS.pageLanguageKey)
        ) {
          return;
        }

        fetchPageFragment();
      });
    };

    const fetchPageFragment = (initialLoad = false) => {
      $mainPageContent?.classList.remove(CONSTANTS.mainContentActiveClass);
      // for every selection change, set values in local storage and update `pageFragId`
      // if initial page load, skip this step and directly fetch the content
      if (!initialLoad) {
        const countryValue = $countryDropdown?.value;
        const languageValue = $languageDropdown?.value;

        // Add to local storage
        localStorage.setItem(CONSTANTS.pageCountryKey, countryValue);
        localStorage.setItem(
          CONSTANTS.pageLanguageKey,
          languageValue && languageValue !== '--' ? languageValue : ''
        );

        // update `pageFragId`
        pageFragId = `${countryValue}${
          languageValue && languageValue !== '--' ? `-${languageValue}` : ''
        }`;
      }

      const $pageFragment = document.querySelector(`.emu-pf#${pageFragId}`);

      // if `$pageFragment` is already loaded, directly update the page content
      if ($pageFragment?.classList.contains('pf-loaded')) {
        const $content = $pageFragment.querySelector(
          '.aaaem-pf__inner .country-pf'
        );

        // add timer to make the animation work when `$pageFragment` is already loaded
        if (pfLoadTimer) {
          clearTimeout(pfLoadTimer);
        }

        pfLoadTimer = setTimeout(() => {
          updatePageContent($content, initialLoad);
        }, 400);
      } else {
        window.Bus.on('emu-pf:loaded', ({ id }) => {
          pagePFCallback(id, pageFragId, $pageFragment, initialLoad);
        });

        window.Bus.on('emu-pf:loadingFailed', ({ id }) => {
          pagePFCallback(id, pageFragId, $pageFragment, initialLoad, false);
        });

        // load page fragment and extract the content
        window.Bus.emit(`emu-pf-${pageFragId}-load`);
      }
    };

    const pagePFCallback = (
      id,
      pageFragId,
      $pageFragment,
      initialLoad,
      isLoaded = true
    ) => {
      if (id === pageFragId) {
        if (isLoaded) {
          $pageFragment.classList.add('pf-loaded');
        } else {
          $pageFragment.classList.add('pf-load-failed');
        }

        if (pfShowTimer) {
          clearTimeout(pfShowTimer);
        }

        // timer to let the DOM settle
        pfShowTimer = setTimeout(() => {
          const $content = $pageFragment.querySelector(
            '.aaaem-pf__inner .country-pf'
          );

          updatePageContent($content, initialLoad);
        }, 400);
      }
    };

    const updatePageContent = ($content, initialLoad) => {
      $mainPageContent?.classList.remove(CONSTANTS.mainContentActiveClass);
      $mainPFContent = $content?.querySelector(
        `.${CONSTANTS.pfMainContentClass}`
      );
      $footerPFContent = $content?.querySelector(
        `.${CONSTANTS.pfFooterContentClass}`
      );

      if ($mainPFContent && $footerPFContent) {
        // update the main page content using `cloneNode` as we don't want to change the loaded PF
        $mainPageContent.innerHTML = '';
        const $newPageContent = $mainPFContent.cloneNode(true);
        $mainPageContent.append($newPageContent);

        // update the footer content using `cloneNode` as we don't want to change the loaded PF
        $footerPageContent.innerHTML = '';
        const $newFooterContent = $footerPFContent.cloneNode(true);
        $footerPageContent.append($newFooterContent);

        // re-initialize variables and append events (with `initialLoad` set to `false`)
        initVariables(false);
        appendEvents();

        // check if logout is clicked when the page is loaded initially
        if (initialLoad) {
          checkLogoutClicked();
        }
      }

      $mainPageContent?.classList.add(CONSTANTS.mainContentActiveClass);
    };

    // if logout is clicked, skip country selector and navigate to the home page by clicking submit button
    const checkLogoutClicked = () => {
      if (logoutClicked !== '') {
        $submitButton?.click();

        // clear `logoutClicked` flag from  localStorage
        localStorage.removeItem(CONSTANTS.logoutClickedLSKey);
      }
    };

    initVariables();
    appendEvents();

    // If there is a deep linking URL available, do not show the country selector page.
    // Else, wait for 10 seconds and show. This is just an edge case to show some content. Coz if deep linking URL is present, it is expected to redirect to that specific page
    if (deepLinkingURL) {
      setTimeout(() => {
        $countrySelectorEl.classList.add(CONSTANTS.countrySelectorShowClass);
      }, 10000);
    } else {
      $countrySelectorEl.classList.add(CONSTANTS.countrySelectorShowClass);
    }
  };

  const init = () => {
    const $countrySelectors = document.querySelectorAll(
      `.${CONSTANTS.countrySelectorClass}`
    ) as NodeListOf<HTMLElement>;
    $countrySelectors?.forEach($countrySelector => {
      initCountrySelector($countrySelector);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
