(() => {
  // Get the plyr instance and play the video in fullscreen mode
  const handleButtonClick = (instanceId: string) => {
    const plyrInstance = window._plyrInstances[instanceId];

    plyrInstance?.play();
    plyrInstance?.fullscreen.enter();
  };

  // Setup event handler(s) for video fullscreen
  const setupVideoEvents = () => {
    const ctaButtons = document.querySelectorAll('a[href^="#fullscreen-"]');

    ctaButtons.forEach(ctaButton => {
      const ctaLink = ctaButton.getAttribute('href');
      const instanceId = ctaLink?.replace('#fullscreen-', '');

      if (instanceId) {
        ctaButton.addEventListener('click', (e) => {
          e.preventDefault();
          handleButtonClick(instanceId);
        });
      }
    });
  };

  const init = () => {
    setupVideoEvents();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
