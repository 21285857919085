(() => {
  const appendEvents = $formContainer => {
    let $inps = $formContainer.querySelectorAll('input, textarea');
    const $ctas = $formContainer.querySelectorAll('button, a') as NodeListOf<
      HTMLButtonElement | HTMLAnchorElement
    >;
    $ctas.forEach($cta => {
      $cta.addEventListener('click', e => {
        e.preventDefault();
        $inps.forEach($el => {
          $el.value = '';
          $el.focus?.();
          window.Bus.emit('aaous-text-field-with-close:reset', { id: $el.id });
        });
      });
    });
  };

  const handleInputWithClose = () => {
    const $formInpsWithClose = document.querySelectorAll(
      '.text-field-with-close'
    ) as NodeListOf<HTMLFormElement>;
    $formInpsWithClose.forEach($formContainer => {
      appendEvents($formContainer);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', handleInputWithClose);
  } else {
    handleInputWithClose();
  }
})();
