(() => {
  const init = () => {
    // when window is resized, re-calculating accordion panels' height
    window.addEventListener('resize', () => {
      const badgerInstances =
        window._badgerInstances && Object.values(window._badgerInstances);
      if (badgerInstances?.length) {
        badgerInstances.forEach(inst => {
          inst?.calculateAllPanelsHeight?.();
        });
      }
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
