import { getCSRFToken } from '../../utils';

(() => {
  const CONSTANTS = {
    resourceOrderModalId: 'resource-order-modal',
    resourceOrderCTAClass: 'resources-order-trigger',
    userInfoEndpoint: '/content/aa-ous/services/user.info.json',
  };

  window._orderMaterialData = window._orderMaterialData || {};

  // handles the order modal
  const handleOrderModal = ($modal: HTMLElement) => {
    let $form: HTMLFormElement,
      formId: string,
      endpoint: string,
      productSKU: string,
      orderSKU: string,
      resourceName: string,
      $resourceNameEls: NodeListOf<HTMLElement>,
      $mailingAddressEls: NodeListOf<HTMLElement>,
      $orderSKUEls: NodeListOf<HTMLElement>,
      $modalTriggerEls: NodeListOf<HTMLAnchorElement>,
      $unitEls: NodeListOf<HTMLElement>,
      $unitSelectionEl: HTMLSelectElement,
      $closeModalCTA: HTMLButtonElement,
      $modalCloseIconCTA: HTMLButtonElement;

    // populates the unit count in the corresponding fields
    const updateUnits = unitCount => {
      if (unitCount && unitCount !== '--') {
        $unitEls.forEach($el => {
          $el.innerText = unitCount;
        });
      }
    };

    // populates the resource name in the corresponding fields
    const updateResourceName = resourceName => {
      if (resourceName) {
        $resourceNameEls.forEach($el => {
          $el.innerText = resourceName;
        });
      }
    };

    // populates the order sku in the corresponding fields
    const updateOrderSKU = orderSKU => {
      if (orderSKU) {
        $orderSKUEls.forEach($el => {
          $el.innerText = orderSKU;
        });
      }
    };

    // sets the user data in window variable
    const setUserData = async () => {
      if (window._orderMaterialData.userData) {
        return;
      }

      try {
        window.Bus.emit('aaous-step-form:show-loader', {
          id: formId,
        });
        const userDataResp = await fetch(
          `${window.location.origin}${CONSTANTS.userInfoEndpoint}`,
          {
            headers: {
              'CSRF-Token': await getCSRFToken(),
            },
          }
        );
        if (!userDataResp.ok) {
          console.warn(`Response status: ${userDataResp.status}`);
        } else {
          const json = await userDataResp.json();
          const userData = {
            address: {
              addressLine1: json.addressLine1,
              addressLine2: json.addressLine2,
              addressLine3: json.addressLine3,
              addressLine4: json.addressLine4,
              city: json.city,
              country: json.country,
              state: json.state,
              postalCode: json.postalCode,
            },
            email: json.email,
            fullName: json.fullName,
          };
          window._orderMaterialData.userData = userData;
        }
        window.Bus.emit('aaous-step-form:hide-loader', {
          id: formId,
        });
      } catch (e) {
        window.Bus.emit('aaous-step-form:hide-loader', {
          id: formId,
        });
        console.warn(e);
      }
    };

    // populates the user data in the corresponding fields
    const updateUserData = async () => {
      await setUserData();

      if (window._orderMaterialData.userData) {
        $mailingAddressEls.forEach($el => {
          const addressList = [
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'addressLine4',
            'city',
            'state',
            'country',
            'postalCode',
          ];
          let addrHTML = '';
          const data = window._orderMaterialData.userData;
          addressList.forEach(item => {
            if (data.address?.[item]) {
              addrHTML += `<span>${data.address[item]}</span>`;
            }
          });
          $el.innerHTML = addrHTML;
        });
      }
    };

    /**
     * Generates orderSKU  based on this pattern -
     * 1st char = Initial of the user’s first name
     * 2nd char = Initial of the user’s last name
     * 3rd to 8th chars = Last 6 digits of current time in milliseconds
     * 9th & 10th chars = First 2 letters of the name of the asset/product
     */
    const generateOrderSKU = () => {
      const { fullName } = window._orderMaterialData.userData;
      const nameParts = fullName.split(' ');
      const firstName = nameParts[0];
      const lastName = nameParts[nameParts.length - 1];
      const currentTime = Date.now().toString();

      const orderSKU = `${firstName ? firstName.charAt(0) : ''}${
        lastName ? lastName.charAt(0) : ''
      }${currentTime.slice(-6)}${
        resourceName ? resourceName.slice(0, 2).toUpperCase() : ''
      }`;

      return orderSKU;
    };

    // submits the order form
    const submitOrderForm = async data => {
      try {
        if (
          endpoint &&
          productSKU &&
          resourceName &&
          window._orderMaterialData
        ) {
          window.Bus.emit('aaous-step-form:show-loader', {
            id: formId,
          });

          orderSKU = generateOrderSKU();
          updateOrderSKU(orderSKU);

          const { userData } = window._orderMaterialData;

          const requestBody = {
            name: userData.fullName,
            date: new Date().toISOString().split('T')[0],
            address: userData.address.addressLine1,
            email: userData.email,
            product_Sku: productSKU,
            quantity: data?.fullFormData.quantity,
            order_Request_Id: orderSKU,
            sendToEmail: data?.fullFormData.sendToEmail,
          };

          const queryParams = new URLSearchParams(data?.fullFormData);

          const resp = await fetch(`${endpoint}?${queryParams}`, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
              'CSRF-Token': await getCSRFToken(),
              'Content-Type': 'application/json',
            },
          });

          if (resp.ok) {
            window.Bus.emit('aaous-step-form:show-success-msg', {
              id: formId,
            });
          } else {
            window.Bus.emit('aaous-step-form:show-error-msg', {
              id: formId,
            });
          }

          window.Bus.emit('aaous-step-form:hide-loader', {
            id: formId,
          });
        }
      } catch (e) {
        window.Bus.emit('aaous-step-form:hide-loader', {
          id: formId,
        });

        window.Bus.emit('aaous-step-form:show-error-msg', {
          id: formId,
        });

        console.warn(e);
      }
    };

    // appending the events
    const appendEvents = () => {
      // triggered when modal trigger is clicked
      // pass the id of the modal, resourceName and productSKU
      window.Bus.on('aaous-order-form:show', data => {
        if (
          data.id === CONSTANTS.resourceOrderModalId &&
          data?.resourceName &&
          data?.productSKU
        ) {
          productSKU = data.productSKU;
          resourceName = data.resourceName;
          updateResourceName(data.resourceName);
          updateUserData();
        }
      });

      // when submit button is clicked inside the form
      window.Bus?.on('aaous-step-form:submit-clicked', data => {
        if (data.id === formId) {
          submitOrderForm(data);
        }
      });

      // modal is closed reset the form
      window.Bus?.on('emu-modal:close', ({ id }) => {
        if (id === CONSTANTS.resourceOrderModalId) {
          // timer to make sure that the modal gets hidden before the form fields gets reset
          setTimeout(() => {
            window.Bus.emit('aaous-step-form:reset', { id: formId });
          }, 500);
        }
      });

      // when modal trigger is clicked, update the resource related details in the modal
      // the actual functionality of showing the modal will be handled by aaaem, as data-modal attribute is already added to the anchor in HTML
      $modalTriggerEls.forEach(($el: HTMLAnchorElement) => {
        $el.addEventListener('click', () => {
          const resourceName = $el.getAttribute('data-title');
          const productSKU = $el.getAttribute('data-product-sku');
          const modalID = $el.getAttribute('data-modal');
          window.Bus.emit('aaous-order-form:show', {
            id: modalID,
            productSKU,
            resourceName,
          });
        });
      });

      // when unit selector is changed, update the unit selection field
      $unitSelectionEl.addEventListener('change', () => {
        updateUnits($unitSelectionEl.value);
      });

      // close the modal when close CTA is clicked
      $closeModalCTA.addEventListener('click', () => {
        $modalCloseIconCTA?.click?.();
      });
    };

    // initiating the variables used across the file
    const initVariables = () => {
      $form = $modal.querySelector('.step-form') as HTMLFormElement;
      formId = $form?.id;
      endpoint = $form?.getAttribute('action') || '';
      $resourceNameEls = $form?.querySelectorAll(
        '.order-materials__label-container--resource .order-materials__label-value'
      ) as NodeListOf<HTMLElement>;
      $mailingAddressEls = $form?.querySelectorAll(
        '.order-materials__label-container--address .order-materials__label-value'
      ) as NodeListOf<HTMLElement>;
      $orderSKUEls = $form?.querySelectorAll(
        '.order-materials__label-container--order-sku .order-materials__label-value'
      ) as NodeListOf<HTMLElement>;
      $modalTriggerEls = document.querySelectorAll(
        `.${CONSTANTS.resourceOrderCTAClass}`
      ) as NodeListOf<HTMLAnchorElement>;
      $unitEls = $form?.querySelectorAll(
        '.order-materials__label-container--units .order-materials__label-value'
      ) as NodeListOf<HTMLElement>;
      $unitSelectionEl = $form.querySelector(
        '.order-materials__unit-selector select'
      ) as HTMLSelectElement;
      $closeModalCTA = $modal?.querySelector(
        '.order-materials__close'
      ) as HTMLButtonElement;
      $modalCloseIconCTA = $modal?.querySelector(
        '.close.main-close'
      ) as HTMLButtonElement;
    };

    initVariables();
    appendEvents();
  };

  const init = () => {
    const $orderModals = document.querySelectorAll(
      `[data-component="modal"][data-id="${CONSTANTS.resourceOrderModalId}"]`
    ) as NodeListOf<HTMLElement>;

    $orderModals.forEach($modal => {
      handleOrderModal($modal);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
