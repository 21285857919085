(() => {
  let knownVideoFormats = ['mp4', 'webm'];

  // for a given src, returns type of the media the src belongs to
  const getTypeOfMedia = (mediaSrc = '') => {
    if (mediaSrc.includes('vimeo.com')) {
      return 'vimeo';
    } else if (mediaSrc.includes('youtube')) {
      return 'youtube';
    } else {
      const matchingVideoFormat = knownVideoFormats.filter(format =>
        mediaSrc.includes(`.${format}`)
      );
      if (matchingVideoFormat?.length) {
        return `video/${matchingVideoFormat[0]}`;
      }
    }
  };

  // for a given src, returns `player.source.sources` object
  // `player.source.sources` matches the object from https://github.com/sampotts/plyr#the-source-setter
  const getPlayerSourcesObj = src => {
    if (src) {
      const srcObj: {
        src: string;
        type?: string;
        provider?: string;
      } = {
        src,
      };

      // `type` should be used for HTML5 Video/Audio. `provider` should be used for youtube/vimeo
      const mediaType = getTypeOfMedia(src);
      if (mediaType?.includes('video/')) {
        srcObj.type = mediaType;
      } else {
        srcObj.provider = mediaType;
      }

      if (mediaType) {
        return srcObj;
      }
    }

    return {};
  };

  // generates source object that is to be fed to the "Plyr" sources via source setter
  // https://github.com/sampotts/plyr#the-source-setter for more info.
  const generatePlayerSrcObj = ($el: HTMLElement) => {
    const videoDetails = $el.dataset;
    // src will be src or fallback src
    const mediaSrc = videoDetails.src || videoDetails.fallbackSrc || '';
    const fallbackMediaSrc =
      (videoDetails.src && videoDetails.fallbackSrc) || '';

    const playerSrcObj: {
      title: string;
      type?: 'video';
      sources?: {
        src?: string;
        type?: string;
        provider?: string;
      }[];
    } = {
      title: videoDetails.title || '',
      type: 'video',
      sources: [
        getPlayerSourcesObj(mediaSrc),
        getPlayerSourcesObj(fallbackMediaSrc),
      ],
    };

    playerSrcObj.sources = playerSrcObj.sources?.filter(item => item.src);

    return playerSrcObj;
  };

  const resourceMediaPlyr = $el => {
    let player, $playerElem, $videoElem, $mediaElem;

    // sets the source to the plyr object
    const setSrcObj = mediaObj => {
      // changing player source
      if (player && mediaObj) {
        player.source = mediaObj;
      }
    };

    // checks whether vimeo is supported and updates the source of the plyr object
    const checkAndAddPlyrSources = mediaObj => {
      const { sources } = mediaObj;

      // for a vimeo video, if there is a fallback video, checking whether vimeo is supported, and then changing the player source.
      // if there is a fallback no. of sources will be greater than one.
      if (
        sources?.length > 1 &&
        sources[0].provider === 'vimeo' &&
        sources[0].src
      ) {
        fetch(
          `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(
            sources[0].src
          )}`
        )
          .then(
            resp => resp.json(),
            () => null
          )
          .then(
            () => {
              // DO NOTHING - intentionally
            },
            () => {
              // removing vimeo src from the sources object if the vimeo video is not available/supported. Removing so that it does not have to process it the next time.
              mediaObj.sources.splice(0, 1);
            }
          )
          .catch(() => {
            // removing vimeo src from the sources object if the vimeo video is not available/supported. Removing so that it does not have to process it the next time.
            mediaObj.sources.splice(0, 1);
          })
          .finally(() => {
            setSrcObj(mediaObj);
          });
      } else {
        setSrcObj(mediaObj);
      }
    };

    // initiates the player
    const initiatePlayer = () => {
      const plyrConfig = {
        controls: [
          'play-large',
          'play',
          'progress',
          'mute',
          'volume',
          'fullscreen',
        ],
        hideControls: false,
      };

      if (window.AAAEM?.plugins?.plyr) {
        player = new window.AAAEM.plugins.plyr($playerElem, plyrConfig);
      }
    };

    // appending events to the elements on the page
    const appendEvents = () => {
      const mediaObject = generatePlayerSrcObj($videoElem);
      initiatePlayer();
      checkAndAddPlyrSources(mediaObject);
      $mediaElem.classList.add('resource-details__media--show-video');
    };

    // initiating the variables that will be used across the file
    const initVariables = () => {
      $videoElem = $el;
      $playerElem = $el.querySelector('.resource-details__plyr-placeholder');
      $mediaElem = $el.closest('.resource-details__media');
    };

    const init = () => {
      initVariables();
      appendEvents();
    };

    init();
  };

  const init = () => {
    const videos = document.querySelectorAll('.resource-details__video');
    if (videos?.length > 0) {
      videos?.forEach(video => {
        resourceMediaPlyr(video);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
