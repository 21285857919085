(() => {
  // the form once submitted is removing the values of dropdown elements
  // making sure that there is a value for the select dropdown
  const handleDropdowns = form => {
    const selectElements = form?.querySelectorAll('select');
    selectElements?.forEach?.(el => {
      if (el.value === '') {
        el.value = el.querySelector('option')?.value;
      }
    });
  };

  // hidden form fields are getting reset when form is submitted once.
  // Making sure that the value is retained by adding a data attribute and adding the value of it to hidden inp fields
  const handleHiddenFields = form => {
    const hiddenInputEls = form?.querySelectorAll('input[type="hidden"]');
    if (hiddenInputEls?.length) {
      hiddenInputEls.forEach(el => {
        const dataVal = el.getAttribute('data-val');
        if (dataVal) {
          el.value = dataVal;
        } else {
          el.setAttribute('data-val', el.value);
        }
      });
    }
  };

  const resetFormVals = form => {
    handleHiddenFields(form);
    handleDropdowns(form);
    // try catch to make sure this does not break the pages' JS
    try {
      //@ts-ignore
      window.grecaptcha?.reset?.();
    } catch (e) {
      console.warn(e);
    }
  };

  // the form once submitted is removing the values of hidden input elements, and removing the values of the dropdowns
  // for OUS the hidden fields are needed for the form to be submitted well. And the select should have a value
  // as soon as the form is submitted, making sure that the values are getting re-initiated
  // a js-response-success class is being added tot he form when the form is successfully submitted.
  const handleCustomerSupportV2Form = form => {
    const ajaxContainer = form?.closest('.ajaxContainer');

    if (ajaxContainer) {
      let prevState = ajaxContainer?.classList?.contains('js-response-success');

      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'class') {
            const currentState = ajaxContainer?.classList?.contains(
              'js-response-success'
            );
            if (prevState !== currentState) {
              prevState = currentState;
              setTimeout(() => {
                resetFormVals(form);
              }, 500);
            }
          }
        });
      });

      observer.observe(ajaxContainer, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }
  };

  const initCustomerSupportFormV2 = () => {
    const el = document.querySelector('#contact-customer-support');

    if (el) {
      handleCustomerSupportV2Form(el);
      resetFormVals(el);

      window.Bus?.on('emu-modal:close', ({ id }) => {
        if (id === 'customer-support-modal-v2') {
          resetFormVals(el);
        }
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initCustomerSupportFormV2);
  } else {
    initCustomerSupportFormV2();
  }
})();
