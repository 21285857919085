//This functions hides the tip banner when clicked on close button
(() => {
  const tipBannerLoad = () => {
    const $tipBannerClose = document.querySelectorAll(
      '.tip-banner__close'
    ) as NodeListOf<HTMLElement>;

    $tipBannerClose.forEach($close => {
      const $banner = $close.closest('.tip-banner');
      if ($banner) {
        $close?.addEventListener('click', () => {
          $banner?.classList?.add('tip-banner--hidden');
        });
      }
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', tipBannerLoad);
  } else {
    tipBannerLoad();
  }
})();
