(() => {
  // handles click event of the logout link. Makes an API call when clicked
  const handleLogout = () => {
    const logoutCtas = document.querySelectorAll(
      '.logout-action'
    ) as NodeListOf<HTMLAnchorElement>;
    const pagePath = document
      .querySelector('.app-container')
      ?.getAttribute('data-page-path');
    let autoLogoutIntervalTimer;

    if (pagePath && logoutCtas?.length) {
      logoutCtas.forEach(el => {
        const href = el.getAttribute('href');
        if (href) {
          el.addEventListener('click', e => {
            e.preventDefault();
            const queryParams = new URLSearchParams({
              path: pagePath,
            });
            // get call to this endpoint will make a call
            // server removes the cookie related to AEM Login
            // as soon as this call responds with a 200 status, user can be redirected to the ICC page
            fetch(
              `/content/aa-ous/services/logout.api.json?${queryParams}`
            ).then(data => {
              if (data?.status === 200) {
                window.location.href = href;
              }
            });
          });

          // automatically clicking the logout link if it has a data-duration attribute
          // useful for error pages, where the data for user is not available and user must be forcefully logged out
          const autoLogoutDuration = el.getAttribute('data-duration');

          if (autoLogoutDuration) {
            clearInterval(autoLogoutIntervalTimer);
            const intDuration = parseInt(autoLogoutDuration, 10);
            const duration = !Number.isNaN(intDuration) ? intDuration : 5;
            const timerText = el
              .closest('.error-container')
              ?.querySelector('.timeout-text .emphasis') as HTMLSpanElement;

            let timerCounter = 0;
            const intervalTimer = () => {
              autoLogoutIntervalTimer = setTimeout(() => {
                if (timerCounter < duration) {
                  if (timerText) {
                    timerText.innerText = `${duration - timerCounter}`;
                  }
                  timerCounter += 1;
                  intervalTimer();
                } else {
                  el.click?.();
                  clearTimeout(autoLogoutIntervalTimer);
                }
              }, 1000);
            };

            intervalTimer();
          }
        }
      });
    }
  };

  const init = () => {
    if (
      window.AAAEM?.isAuthorMode ||
      window.frameElement?.id === 'ContentFrame'
    ) {
      return;
    }
    handleLogout();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
