(() => {
  // converts v1 buttons to v2.
  // In some cases where buttons are embedded into other aaaem-components v1 buttons are being added instead of v2.
  // This converts such buttons to v2
  // Modal is one such example
  const convertButtonsToV2 = () => {
    const v1Buttons = document.querySelectorAll('.aaaem-button.emu-button');
    if (v1Buttons?.length) {
      v1Buttons.forEach(cta => {
        let ctaType;
        cta.classList.forEach(name => {
          if (name.includes('aaaem-button__')) {
            ctaType = name.replace('aaaem-button__', '');
          }
        });

        cta.className = '';
        cta.classList.add('emu-button', 'emu-button-v2');

        if (ctaType) {
          cta.classList.add(`emu-button__${ctaType}`);
        }
      });
    }
  };

  const init = () => {
    convertButtonsToV2();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
