import { debounce } from '../../utils';
(() => {
  // when carousel has less content width than its container width, adding a class to hide arrows, dots and to center the content horizontally
  const adjustCarouselDimensions = () => {
    const carouseInstances = Object.values(window._tnsInstances || {});
    if (carouseInstances?.length) {
      const updateSliderHeight = inst => {
        //@ts-ignore
        const $activeSlideImages = inst
          .getInfo()
          ?.container?.querySelectorAll?.(
            '.tns-slide-active img'
          ) as NodeListOf<HTMLImageElement>;
        $activeSlideImages.forEach($img => {
          // making sure that the carousel height is updated when every image is loaded
          if ($img?.complete) {
            //@ts-ignore
            inst?.updateSliderHeight?.();
          } else if ($img) {
            $img.addEventListener('load', () => {
              //@ts-ignore
              inst?.updateSliderHeight?.();
            });
          }
        });
        inst?.updateSliderHeight?.();
      };

      carouseInstances.forEach(inst => {
        updateSliderHeight(inst);

        // making sure that the slider height is updated on every transition
        // @ts-ignore
        inst?.events?.on('transitionEnd', () => {
          updateSliderHeight(inst);
        });
      });

      window.Bus?.on('emu-carousel:inview', ({ id }) => {
        const inst = window._tnsInstances?.[id];
        updateSliderHeight(inst);
      });
    }
  };

  // updates the TNS Live Region Text to include text in 1/8(current/total) format
  const addTnsLiveRegionText = () => {
    const carouseInstances = Object.values(window._tnsInstances || {});
    if (carouseInstances?.length) {
      carouseInstances.forEach(inst => {
        // @ts-ignore
        const curInfo = inst?.getInfo?.();

        const $liveRegion = curInfo?.container
          ?.closest?.('.carousel')
          ?.querySelector?.('.tns-liveregion') as HTMLElement;

        const updateLiveRegion = info => {
          try {
            const curIndex = info.navCurrentIndex + 1;
            const total = info.slideCount;
            $liveRegion.innerHTML = curIndex + '/' + total;
            $liveRegion.classList.add('can-show');
          } catch (e) {
            console.warn(e);
            $liveRegion.classList.remove('can-show');
          }
        };

        if ($liveRegion) {
          // @ts-ignore
          inst?.events?.on?.('transitionEnd', info => {
            updateLiveRegion(info);
          });

          updateLiveRegion(curInfo);
        }
      });
    }
  };

  // when the carousel controls are hidden, make the carousel disabled
  const adjustDisabledState = () => {
    let winWidth = window.innerWidth;

    const $carousels = document.querySelectorAll(
      '.emu-carousel'
    ) as NodeListOf<HTMLElement>;

    const checkAndMakeCarouselDisabled = () => {
      $carousels.forEach($el => {
        const $controls = $el.querySelector('.tns-controls');
        const styleStr = $controls?.getAttribute('style') || '';
        if (styleStr) {
          $el.classList.toggle(
            'u-carousel--disabled',
            styleStr?.replace(/ /g, '').indexOf('display:none') >= 0
          );
        }
      });
    };
    checkAndMakeCarouselDisabled();

    window.addEventListener(
      'resize',
      debounce(() => {
        const curWidth = window.innerWidth;
        if (curWidth !== winWidth) {
          winWidth = curWidth;
          checkAndMakeCarouselDisabled();
        }
      }, 500)
    );
  };

  const init = () => {
    adjustCarouselDimensions();
    addTnsLiveRegionText();
    adjustDisabledState();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
