(() => {
  // makes the cta disabled
  const makeCTADisabled = ($cta: HTMLButtonElement | HTMLAnchorElement) => {
    $cta.setAttribute('disabled', 'disabled');
    $cta?.classList.add('btn--disabled');
  };

  // makes the cta enabled
  const makeCTAEnabled = ($cta: HTMLButtonElement | HTMLAnchorElement) => {
    $cta.removeAttribute('disabled');
    $cta?.classList.remove('btn--disabled');
  };

  // adds events to the confirm cta to disable/enable and when confirm cta is clicked
  const addEventsToConfirmCTA = (
    $cta: HTMLButtonElement | HTMLAnchorElement,
    modalId: string
  ) => {
    // makes the confirm button enabled
    window.Bus.on('aaous-modal:enable-confirm', ({ id }) => {
      if (id === modalId) {
        makeCTAEnabled($cta);
      }
    });

    // makes the confirm button disabled
    window.Bus.on('aaous-modal:disable-confirm', ({ id }) => {
      if (id === modalId) {
        makeCTADisabled($cta);
      }
    });

    // when confirm cta is clicked, emits and event aaous-modal:confirm-click
    $cta.addEventListener('click', e => {
      const href = $cta.getAttribute('href');

      if (href === '#' || window.location.href + '#' === href) {
        e.preventDefault();
      }

      window.Bus.emit('aaous-modal:confirm-click', {
        id: modalId,
      });
    });
  };

  // when there are required checkboxes, the cta must be disabled unless all the checkboxes are checked
  const handleReqCheckboxes = ($checkboxes, $cta) => {
    if ($cta) {
      makeCTADisabled($cta);
    }

    const checkAndEnableCTA = () => {
      let allChecked = true;
      $checkboxes.forEach($checkbox => {
        if ($checkbox.checked !== true) {
          allChecked = false;
        }
      });

      if (allChecked) {
        makeCTAEnabled($cta);
      } else {
        makeCTADisabled($cta);
      }
    };

    $checkboxes.forEach($checkbox => {
      $checkbox.addEventListener('change', () => {
        checkAndEnableCTA();
      });
    });
  };

  // when modal is closed, reset the form
  const handleFormsInsideModals = (
    $forms,
    modalId,
    $requiredCheckboxes,
    $confirmCTA
  ) => {
    window.Bus?.on('emu-modal:close', ({ id }) => {
      if (id === modalId) {
        $forms.forEach($form => {
          const formId = $form?.id;
          let timer;
          if (formId) {
            if (timer) {
              clearTimeout(timer);
              timer = null;
            }

            // making sure that the modal is completely closed before resetting the form, and disabling the CTAs
            timer = setTimeout(() => {
              window.Bus.emit('aaous-form:reset', { id: formId });
              handleReqCheckboxes($requiredCheckboxes, $confirmCTA);
            }, 500);
          }
        });
      }
    });
  };

  // handles the modal for the checkboxes and cta functionality
  const handleModal = ($modal: HTMLElement) => {
    const modalId = $modal?.getAttribute('data-id') as string;
    const $confirmCTA = $modal.querySelector(
      '[data-ref="confirm-cta"] [data-emu-type="button"]'
    ) as HTMLAnchorElement | HTMLButtonElement;
    const $requiredCheckboxes = $modal.querySelectorAll(
      '.emu-form-checkbox[data-required="true"] input'
    );
    const $forms = $modal.querySelectorAll('form');

    if ($confirmCTA) {
      addEventsToConfirmCTA($confirmCTA, modalId);
    }

    // if there are required checkboxes and confirm CTAs, unless all checkboxes are checked, disable the confirm CTA
    if ($requiredCheckboxes?.length && $confirmCTA) {
      handleReqCheckboxes($requiredCheckboxes, $confirmCTA);
    }

    // if there are forms, on modal close, reset the forms
    if ($forms?.length) {
      handleFormsInsideModals(
        $forms,
        modalId,
        $requiredCheckboxes,
        $confirmCTA
      );
    }
  };

  const init = () => {
    const $modals = document.querySelectorAll(
      '[data-component="modal"]'
    ) as NodeListOf<HTMLElement>;
    if ($modals?.length) {
      $modals.forEach($modal => {
        handleModal($modal);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
