(() => {
  const initAMIComponent = (el: HTMLElement) => {
    let data, amiResp, isAMIUser, hasCourses, hasEvents;
    let $mainAMIContainer,
      $eventsContainer,
      $coursesContainer,
      $eventsStage,
      $coursesStage,
      $amiLoader,
      $coursesPlaceholderImg,
      coursesPlaceholderImgSrc,
      $msgProvider,
      $nonAMICoursesMsg,
      $noCoursesMsg,
      $nonAMIEventsMsg,
      $noEventsMsg;

    let CONSTANTS = {
      classNames: {
        mainAMIContainer: 'ami__wrapper',
        eventsTab: 'ami__tab-content--events',
        coursesTab: 'ami__tab-content--courses',
        eventsStage: 'ami__stage--events',
        coursesStage: 'ami__stage--courses',
        nonAMIEventsMsg: 'ami__msg--non-ami-events',
        nonAMICoursesMsg: 'ami__msg--non-ami-courses',
        noEventsMsg: 'ami__msg--no-events',
        noCoursesMsg: 'ami__msg--no-courses',
        loader: 'ami__loader',
        showLoader: 'loader--active',
        nonAMIUser: 'ami__wrapper--non-ami',
        noCourses: 'ami__wrapper--no-courses',
        noEvents: 'ami__wrapper--no-events',
        wrapperHasMessage: 'ami__wrapper--has-msg',
        hasMessage: 'ami__tab-content--has-msg',
        card: 'ami__card',
        cardEntityTypePretitle:
          'ami__card-content-pretitle ami__card-content-pretitle--type u-body-font--eyebrow',
        cardEntityDatePretitle:
          'ami__card-content-pretitle ami__card-content-pretitle--date u-body-font--eyebrow',
        cardInnerContainer: 'ami__card-inner-container',
        cardImgContainer: 'ami__card-img-container',
        cardImgContainerNoImg: 'ami__card-img-container--no-img',
        cardImg: 'ami__card-img',
        cardContentContainer: 'ami__card-content-container',
        cardContent: 'ami__card-content',
        cardContentTitle: 'ami__card-content-title',
        cardContentCTA: 'button ami__card-content-cta',
        cardContentAnchor:
          'emu-button emu-button-v2 emu-button__primary-outline u-body-font--cta  btn--as-link',
        cardPretitleContainer: 'ami__card-content-pretitle-container',
        msgProvider: 'ami__msg-provider',
      },
    };

    // initializes the variables used across the file
    const initVariables = () => {
      data = el?.dataset;
      $mainAMIContainer = el.closest('.ami__wrapper');
      $eventsContainer = $mainAMIContainer?.querySelector(
        '.ami__tab-content--events'
      );
      $coursesContainer = $mainAMIContainer?.querySelector(
        '.ami__tab-content--courses'
      );
      $eventsStage = $mainAMIContainer?.querySelector('.ami__stage--events');
      $coursesStage = $mainAMIContainer?.querySelector('.ami__stage--courses');
      $amiLoader = $mainAMIContainer?.querySelector('.ami__loader');
      $coursesPlaceholderImg = $mainAMIContainer?.querySelector(
        '.ami__placeholder-img--courses img'
      ) as HTMLImageElement;
      coursesPlaceholderImgSrc =
        $coursesPlaceholderImg?.getAttribute('src') || '';
      $msgProvider = $mainAMIContainer?.querySelector('.ami__msg-provider');
      $nonAMICoursesMsg = $msgProvider?.querySelector(
        '.ami__msg--non-ami-courses'
      );
      $noCoursesMsg = $msgProvider?.querySelector('.ami__msg--no-courses');
      $nonAMIEventsMsg = $msgProvider?.querySelector(
        '.ami__msg--non-ami-events'
      );
      $noEventsMsg = $msgProvider?.querySelector('.ami__msg--no-events');
    };

    // adds class to the ami__wrapper element, so that error messages can be shown accordingly
    const handleAMIMessages = () => {
      if (isAMIUser) {
        if (hasCourses !== true) {
          $mainAMIContainer?.classList.add(CONSTANTS.classNames.noCourses);
          $coursesContainer?.classList.add(CONSTANTS.classNames.hasMessage);
          $mainAMIContainer?.classList.add(
            CONSTANTS.classNames.wrapperHasMessage
          );
        }

        if (hasEvents !== true) {
          $mainAMIContainer?.classList.add(CONSTANTS.classNames.noEvents);
          $eventsContainer?.classList.add(CONSTANTS.classNames.hasMessage);
          $mainAMIContainer?.classList.add(
            CONSTANTS.classNames.wrapperHasMessage
          );
        }
      } else {
        // non AMI User. Show a message that the AMI content is accessible when registered for AMI.
        $mainAMIContainer?.classList.add(CONSTANTS.classNames.nonAMIUser);
        $coursesContainer?.classList.add(CONSTANTS.classNames.hasMessage);
        $eventsContainer?.classList.add(CONSTANTS.classNames.hasMessage);
        $mainAMIContainer?.classList.add(
          CONSTANTS.classNames.wrapperHasMessage
        );
      }
    };

    // loads AMI data and returns the data
    const loadAMIData = () => {
      $amiLoader?.classList.add(CONSTANTS.classNames.showLoader);
      const endpoint = data?.amiEndpoint;
      if (endpoint) {
        return fetch(endpoint)
          .then(response => {
            try {
              return response.json();
            } catch (error) {
              console.warn(error);
              return null;
            }
          }, handleAMIMessages)
          .then(resp => {
            return resp;
          })
          .catch(error => {
            console.warn('Failed to fetch AMI Data:', error);
            handleAMIMessages();
          })
          .finally(() => {
            $amiLoader?.classList.remove(CONSTANTS.classNames.showLoader);
          });
      }
      return Promise.reject();
    };

    const createEl = (el = 'div', attrs = {}) => {
      const elem = document.createElement(el);
      if (Object.keys(attrs).length) {
        Object.keys(attrs).forEach(attr => {
          elem.setAttribute(attr, attrs[attr]);
        });
      }
      return elem;
    };

    // returns the CTA Text for the course or event based on different parameters
    const getCTAText = (entity, type) => {
      if (type === 'event') {
        return data.amiBtnTextDetails;
      }

      // if the user did not register for the course, show enroll text
      if (entity.fromCatalog) {
        return data.amiBtnTextEnroll;
      } else {
        // if user made progress, show Resume text
        // else if user completed the course don't show any CTA text
        // else show start text
        const progress = parseFloat(entity.progress);
        if (isNaN(progress) !== true) {
          if (progress > 0) {
            return data.amiBtnTextResume;
          }

          if (progress === 100) {
            return '';
          }
          return data.amiBtnTextStart;
        } else {
          return data.amiBtnTextStart;
        }
      }
    };

    // returns a HTML element containing the details of the course/event
    const getAMIEntityEl = (entity, type = 'course') => {
      let $cardImg,
        $cardImgContainer,
        $cardPretitle,
        $cardContentContainer,
        $cardContent,
        $cardContentPretitle,
        $cardContentTitle,
        $cardContentCTA,
        $cardPretitleContainer;

      const $card = createEl('article', {
        class: CONSTANTS.classNames.card,
      }) as HTMLElement;

      // adding entity image only for courses.
      // Remove this condition if events also should have this image
      if (type === 'course') {
        if (entity.imageUrl || coursesPlaceholderImgSrc) {
          $cardImgContainer = createEl('div', {
            class: CONSTANTS.classNames.cardImgContainer,
          }) as HTMLElement;

          $cardImg = createEl('img', {
            class: CONSTANTS.classNames.cardImg,
            src: entity.imageUrl || coursesPlaceholderImgSrc,
            alt: entity.courseName,
          }) as HTMLElement;

          // adding placeholder if image loading failed
          $cardImg.addEventListener('error', () => {
            if (coursesPlaceholderImgSrc) {
              $cardImg.setAttribute('src', coursesPlaceholderImgSrc);
            } else {
              $cardImgContainer.classList.add('u-hide');
            }
          });

          $cardImgContainer.append($cardImg);
        }
      }

      // adding content
      if (entity.courseName) {
        $cardContentContainer = createEl('div', {
          class: CONSTANTS.classNames.cardContentContainer,
        });

        $cardContent = createEl('div', {
          class: CONSTANTS.classNames.cardContent,
        });

        // only when pretitles are present add a container for them
        if (entity.courseType || entity.dateStarted) {
          $cardPretitleContainer = createEl('div', {
            class: CONSTANTS.classNames.cardPretitleContainer,
          });
        }

        // creating entity pre-title (Online Course, Curriculum etc)
        if (entity.courseType) {
          $cardPretitle = createEl('p', {
            class: CONSTANTS.classNames.cardEntityTypePretitle,
          });

          $cardPretitle.innerText = entity.courseType;
          $cardPretitleContainer.append($cardPretitle);
        }

        if (entity.dateStarted) {
          $cardContentPretitle = createEl('p', {
            class: CONSTANTS.classNames.cardEntityDatePretitle,
          });
          $cardContentPretitle.innerText = entity.dateStarted;
          $cardPretitleContainer.append($cardContentPretitle);
        }

        if ($cardPretitleContainer) {
          $cardContent.append($cardPretitleContainer);
        }

        $cardContentTitle = createEl('h5', {
          class: CONSTANTS.classNames.cardContentTitle,
        });
        $cardContentTitle.innerText = entity.courseName;
        $cardContent.append($cardContentTitle);

        // creating the CTA
        const ctaText = getCTAText(entity, type);
        if (ctaText && entity.detailsURL) {
          $cardContentCTA = createEl('div', {
            class: CONSTANTS.classNames.cardContentCTA,
          });

          let target = data.amiEventBtnTarget || '_blank';

          if (type === 'course') {
            target = data.amiCourseBtnTarget || '_blank';
          }

          const $cardContentAnchor = createEl('a', {
            class: CONSTANTS.classNames.cardContentAnchor,
            href: entity.detailsURL,
            target,
          });
          $cardContentAnchor.innerText = ctaText;
          $cardContentCTA.append($cardContentAnchor);
          $cardContent.append($cardContentCTA);
        }

        // appending content to the content container
        $cardContentContainer.append($cardContent);
      }

      // appending image and content
      if ($cardImgContainer || $cardContentContainer) {
        const $cardInnerContainer = createEl('div', {
          class: CONSTANTS.classNames.cardInnerContainer,
        });

        if ($cardImgContainer) {
          $cardInnerContainer.append($cardImgContainer);
        }

        if ($cardContentContainer) {
          $cardInnerContainer.append($cardContentContainer);
        }

        $card.append($cardInnerContainer);
      }

      return $card;
    };

    // append the AMI courses and events to the component
    const appendAMIEntities = () => {
      if (hasCourses) {
        const docFrag = document.createDocumentFragment();
        amiResp.courses.forEach(course => {
          docFrag.append(getAMIEntityEl(course, 'course'));
        });
        $coursesStage.append(docFrag);
      }

      if (hasEvents) {
        const docFrag = document.createDocumentFragment();
        amiResp.events.forEach(event => {
          docFrag.append(getAMIEntityEl(event, 'event'));
        });
        $eventsStage.append(docFrag);
      }
    };

    // adds messages to the AMI component in the respective tabs
    const addAMIMessages = () => {
      if ($nonAMICoursesMsg || $noCoursesMsg) {
        const $coursesMsgContainer = createEl('div', {
          class: 'ami__msg-container u-p-regular u-body-font--h5',
        });
        $nonAMICoursesMsg && $coursesMsgContainer.append($nonAMICoursesMsg);
        $noCoursesMsg && $coursesMsgContainer.append($noCoursesMsg);
        $coursesStage?.append($coursesMsgContainer);
      }

      if ($nonAMIEventsMsg || $noEventsMsg) {
        const $eventsMsgContainer = createEl('div', {
          class: 'ami__msg-container u-p-regular u-body-font--h5',
        });
        $nonAMIEventsMsg && $eventsMsgContainer.append($nonAMIEventsMsg);
        $noEventsMsg && $eventsMsgContainer.append($noEventsMsg);
        $eventsStage?.append($eventsMsgContainer);
      }
    };

    if (el) {
      initVariables();
      addAMIMessages();
      if (data?.amiEndpoint) {
        loadAMIData().then(resp => {
          amiResp = resp;
          isAMIUser = amiResp?.hasAMIConnection;
          hasEvents = amiResp?.events?.length > 0;
          hasCourses = amiResp?.courses?.length > 0;
          handleAMIMessages();
          appendAMIEntities();
        }, handleAMIMessages);
      } else {
        handleAMIMessages();
      }
    }
  };

  // initializing the AMI component JS
  const initiateAMI = () => {
    if (
      window.AAAEM?.isAuthorMode ||
      window.frameElement?.id === 'ContentFrame'
    ) {
      return;
    }

    const amiEls = document.querySelectorAll(
      '.ami__provider'
    ) as NodeListOf<HTMLElement>;

    if (amiEls?.length) {
      amiEls.forEach(el => {
        initAMIComponent(el);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initiateAMI);
  } else {
    initiateAMI();
  }
})();
