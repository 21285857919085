// Usually radio buttons are not resettable.
// But there may be cases where clicking on the selected radio button needs to un-check the radio button.
// add u-resettable-radio-buttons class to the radio buttons and that makes the radio buttons uncheckable

(() => {
  const appendReset = ($radioWrapper: HTMLElement) => {
    const $radios = $radioWrapper.querySelectorAll(
      'input'
    ) as NodeListOf<HTMLInputElement>;
    const radioWrapperId = $radioWrapper.id;
    if (radioWrapperId) {
      $radios.forEach($radio => {
        const $label = $radio.closest('.emu-form-radio__label') as HTMLElement;
        $label.addEventListener('click', e => {
          e.preventDefault();
          if ($radio.checked) {
            window.Bus.emit(`emu-form-radio:reset`, { id: radioWrapperId });
          } else {
            $radio.checked = true;
          }
          $radio.dispatchEvent(new Event('change'));
        });
      });
    }
  };

  const init = () => {
    const $resettableRadioWrappers = document.querySelectorAll(
      '.u-resettable-radio-buttons'
    ) as NodeListOf<HTMLElement>;

    $resettableRadioWrappers.forEach($radioWrapper => {
      appendReset($radioWrapper);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
