import getArticleHTML from './article.template';

(() => {
  const initArticleList = $articleList => {
    let selectedPage = 1;
    const articlesContainerId = 'article-list';
    let resultPerPage,
      servletPath,
      readMoreText,
      totalCardsCount,
      $noResultsMsg: HTMLElement,
      $sortSelect: HTMLSelectElement,
      $typeSelect: HTMLSelectElement,
      $brandSelect: HTMLSelectElement,
      $articlesContainer: HTMLElement,
      $articleLoader: HTMLElement;

    // To initiate variables
    const initVariables = () => {
      servletPath = $articleList?.dataset?.servletPath;
      resultPerPage = $articleList?.dataset?.resultsPerPage;
      readMoreText = $articleList?.dataset?.readMoreText;
      totalCardsCount = $articleList?.dataset?.totalCards;
      $noResultsMsg = $articleList?.querySelector(
        '.article__message'
      ) as HTMLElement;
      $articlesContainer = $articleList?.querySelector(
        `#${articlesContainerId}`
      ) as HTMLElement;
      $sortSelect = $articleList?.querySelector(
        '#article__filter--sort select'
      ) as HTMLSelectElement;
      $typeSelect = document.querySelector(
        '#article__filter--type select'
      ) as HTMLSelectElement;
      $brandSelect = document.querySelector(
        '#article__filter--brand select'
      ) as HTMLSelectElement;
      $articleLoader = $articleList?.querySelector('.article__loader');
    };

    // To toggle loader based on boolean flag
    const toggleLoader = isVisible => {
      $articleLoader?.classList.toggle('loader--active', isVisible);
    };

    // To initiate the Pagination and to update it
    const updatePagination = () => {
      toggleLoader(false);
      window.Bus.emit('emu-pagination:change', {
        paginatedCompId: articlesContainerId,
        currentPage: selectedPage,
        resultsPerPage: resultPerPage,
        totalResults: totalCardsCount,
      });
    };

    // To show to message when there are no articles on a API call
    const addNoArticleMessage = isVisible => {
      toggleLoader(false);
      $noResultsMsg?.classList.toggle('article__message--hidden', isVisible);
    };

    // Update the article list based on the fetched data
    const updateArticleList = articles => {
      const fragment = document.createDocumentFragment();
      articles.forEach(article => {
        const articleCard = document.createElement('div');
        articleCard.classList.add(
          'article__card',
          'article__card--visible',
          'u-py-regular',
          'emu-spacing-px__sm',
          'u-bg--light-grey-100'
        );

        articleCard.innerHTML = getArticleHTML(article, readMoreText);
        fragment.appendChild(articleCard);
      });

      if ($articlesContainer) {
        $articlesContainer.appendChild(fragment);
      }
    };

    // Returns the selected filters as a list of URL parameters
    const getFilterParams = selectedPage => {
      const sortValue = $sortSelect?.value;
      const typeValue = $typeSelect?.value;
      const brandValue = $brandSelect?.value;

      const values = new URLSearchParams({
        sort: sortValue || '',
        filterTags: [typeValue, brandValue].filter(Boolean).join(','),
        page: selectedPage !== undefined ? selectedPage.toString() : '',
      });

      return values;
    };

    //to get list of articles based on filters and selected page
    const fetchCards = (selectedPage = 1) => {
      toggleLoader(true);
      const params = getFilterParams(selectedPage);
      fetch(`${servletPath}?${params.toString()}`)
        .then(response => {
          try {
            return response.json();
          } catch (error) {
            console.warn(error);
            return null;
          }
        })
        .then(resp => {
          toggleLoader(false);
          const hasArticles = resp?.articles?.length > 0;
          totalCardsCount = resp?.totalItemCount;
          updatePagination();

          if ($articlesContainer) {
            $articlesContainer.innerHTML = '';
          }

          if (hasArticles) {
            updateArticleList(resp.articles);
          }

          // show/hide error message
          addNoArticleMessage(hasArticles);
        })
        .finally(() => {
          toggleLoader(false);
        });
    };

    // To update the cards on pagination update and on filters change, using mutation observer to get the filters
    const appendEvents = () => {
      window.Bus.on(
        'emu-pagination:update',
        ({ paginatedCompId, currentPage }) => {
          if (paginatedCompId === articlesContainerId) {
            selectedPage = currentPage;
            fetchCards(selectedPage);
            $articleList?.scrollIntoView();
          }
        }
      );

      $sortSelect?.addEventListener('change', () => {
        selectedPage = 1;
        fetchCards(selectedPage);
      });

      $brandSelect?.addEventListener('change', () => {
        selectedPage = 1;
        fetchCards(selectedPage);
      });

      $typeSelect?.addEventListener('change', () => {
        selectedPage = 1;
        fetchCards(selectedPage);
      });
    };

    initVariables();
    if (totalCardsCount === '0') {
      addNoArticleMessage(false);
    }

    appendEvents();
    updatePagination();
  };

  const init = () => {
    const $articleList = document.querySelectorAll('.article-list');
    $articleList.forEach($el => {
      initArticleList($el);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
