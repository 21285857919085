(() => {
  const CONSTANTS = {
    header: {
      eyebrowHeaderHeightVariable: '--eyebrow-header-height',
      navParentElClass: 'emu-navigation__item-parent',
      navParentElActiveClass: 'emu-navigation__item-parent',
      eyebrowMenuClass: 'header__eyebrow-nav-container',
      eyebrowHeaderHeight: 0,
      loginCtaClass: 'header__cta--login',
    },
  };

  // sets --eyebrow-header-height variable to the body element which is used to maintaining the sticky state of the header
  const setEyebrowHeaderHeight = (header, eyebrowMenu) => {
    if (header && eyebrowMenu) {
      CONSTANTS.header.eyebrowHeaderHeight =
        window.innerHeight < 1024
          ? header.clientHeight
          : eyebrowMenu.clientHeight;
      header.style.setProperty(
        CONSTANTS.header.eyebrowHeaderHeightVariable,
        CONSTANTS.header.eyebrowHeaderHeight + 'px'
      );
    }
  };

  // adds click event on the 2nd level parent elements, to add emu-navigation__item-parent--active to it
  // first level parent elements must show on hover, only 2nd level should show on click event
  const handleNestedMenuItems = header => {
    if (header) {
      const nestedMenuItems = header?.querySelectorAll?.(
        `.${CONSTANTS.header.eyebrowHeaderHeightVariable} .${CONSTANTS.header.eyebrowHeaderHeightVariable}`
      );
      if (nestedMenuItems?.length) {
        nestedMenuItems.forEach(el => {
          el.addEventListener('click', e => {
            e.stopPropagation();
            e.stopImmediatePropagation();
            el.toggleClass(CONSTANTS.header.navParentElActiveClass);
          });
        });
      }
    }
  };

  // when window is scrolled, adding or removing a class based on scroll position
  const handlePageScroll = () => {
    if (window.scrollY > CONSTANTS.header.eyebrowHeaderHeight) {
      document.body.classList.add('has-sticky-header');
    } else {
      document.body.classList.remove('has-sticky-header');
    }
  };

  // handles the events of the header
  // sticky state
  // click events on the nested menu anchors
  const handleHeader = header => {
    const eyebrowMenu = header?.querySelector?.(
      `.${CONSTANTS.header.eyebrowMenuClass}`
    );

    if (header && eyebrowMenu) {
      setEyebrowHeaderHeight(header, eyebrowMenu);
      handleNestedMenuItems(header);
      handleLoginClick(header);

      let winWidth = window.innerWidth;

      window.addEventListener('load', () => {
        setEyebrowHeaderHeight(header, eyebrowMenu);
      });

      window.addEventListener('resize', () => {
        const curWidth = window.innerWidth;
        if (winWidth !== curWidth) {
          winWidth = curWidth;
          setEyebrowHeaderHeight(header, eyebrowMenu);
        }
      });

      handlePageScroll();
      window.addEventListener('scroll', handlePageScroll);
    }
  };

  // clear the resource list filters from local storage on click of login button
  const handleLoginClick = header => {
    const loginButton = header.querySelector(
      `.${CONSTANTS.header.loginCtaClass}`
    );

    if (loginButton) {
      loginButton.addEventListener('click', () => {
        localStorage.removeItem('resourceFilters');
      });
    }
  };

  // adding a class to the body when modal is open to show an overlay below the menu
  const handleMenuOpen = () => {
    if (window.Bus) {
      const bodyEl = document.body;
      let scrollPos = 0;

      window.Bus.on('emu-button:click', ({ id, toggleOn }) => {
        if (id === 'header__nav-trigger') {
          if (toggleOn) {
            scrollPos = document.documentElement.scrollTop;
          }

          bodyEl.style.setProperty('--scrollTopPos', `-${scrollPos}px`);
          bodyEl.classList.toggle('mobile-menu-open', toggleOn);

          if (toggleOn !== true) {
            if (window.innerWidth <= 1023) {
              window.scrollTo({
                top: scrollPos,
                left: 0,
                behavior: 'instant',
              });
            }
          }
        }
      });
    }
  };

  const init = () => {
    const headers = document.querySelectorAll('.header__main-wrapper');

    if (headers.length) {
      headers.forEach(header => {
        handleHeader(header);
      });
    }

    handleMenuOpen();

    //@TODO: ADD Analytics for the header events
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
