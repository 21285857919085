(() => {
  const CONSTANTS = {
    logoutButtonClass: 'my-account__action',
  };

  const handleLogout = anchor => {
    anchor.addEventListener('click', () => {
      localStorage.setItem('logoutClicked', 'true');
    });
  };

  const init = () => {
    const logoutAnchors = document.querySelectorAll(
      `.${CONSTANTS.logoutButtonClass}`
    );
    if (logoutAnchors?.length > 0) {
      logoutAnchors?.forEach(anchor => {
        handleLogout(anchor);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
